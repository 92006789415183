import "./ArticlesText.css";
import { Helmet } from "react-helmet";
import "../FAQ/FAQ.css";
import React from "react";
import { ArticleContext } from "../../context/ArticleContext";
import Form from "../Form/Form";

function ArticlesText(props) {
  const articles = React.useContext(ArticleContext);
  const [refArticle, articleIsVisible] = props.useOnScreen({
    rootMargin: "5px",
  });
  return (
    <section className="article-text" ref={refArticle}>
      {articles.map((art) =>
        `/articles/${art.translit}` === props.location.pathname ? (
          <>
            <Helmet>
              <meta name="description" content={art.subtitle} />
              <meta property="og:title" content={art.title} />
              <meta property="og:description" content={art.subtitle} />
              <title>{art.title}</title>
            </Helmet>
            <div
              key={`articletext${art.key}`}
              className="article-text__container"
            >
              <div className="article-text__author-data">
                <div className="article-text__author-container">
                  <img
                    className="article-text__author-foto"
                    src={art.foto}
                    alt={art.author}
                  />
                  <div className="article-text__author-box">
                    <p className="article-text__author">{art.author}</p>
                    <p className="article-text__author-job">{art.job}</p>
                    <p className="article-text__data">{art.date}</p>
                  </div>
                </div>
              </div>
              <h2 className="article-text__main-title">{art.title}</h2>
              {/* <picture className="article-text__picture">
              <source srcSet={art.webp} type="image/webp" />
              <source srcSet={art.avif} type="image/avif" />
              <source srcSet={art.src} type="image/png" />
              <img
                className="article-text__main-img"
                src={art.src}
                alt={art.title}
                type="image/png"
              />
            </picture>
            <p className="article-text__source">Источник: {art.source}</p> */}
              {art.text.map((a) =>
                a.type === "text" ? (
                  <p className="article-text__text">{a.text}</p>
                ) : a.type === "img" ? (
                  <>
                    <picture className="article-text__picture">
                      <source srcSet={a.webp} type="image/webp" />
                      <source srcSet={a.avif} type="image/avif" />
                      <source srcSet={a.src} type="image/png" />
                      <img
                        className="article-text__main-img"
                        src={a.src}
                        alt={a.source}
                        type="image/png"
                      />
                    </picture>
                    {a.source !== "" ? (
                      <p className="article-text__source">
                        Источник: {a.source}
                      </p>
                    ) : null}

                    {a.text !== "" ? (
                      <p className="article-text__text-img">{a.text}</p>
                    ) : null}
                  </>
                ) : a.type === "title" ? (
                  <h3 className="article-text__title">{a.text}</h3>
                ) : a.type === "list" ? (
                  <ul className="article-text__list">
                    {a.text.map((l) => (
                      <li>{l}</li>
                    ))}
                  </ul>
                ) : a.type === "subtitle" ? (
                  <h3 className="article-text__subtitle">{a.text}</h3>
                ) : a.type === "reference" ? (
                  <div className="article-text__reference-box">
                    {a.text.map((ref) => (
                      <p className="article-text__reference">{ref}</p>
                    ))}
                  </div>
                ) : a.type === "main-subtitle" ? (
                  <h3 className="article-text__main-subtitle">{a.text}</h3>
                ) : a.type === "form" ? (
                  <Form
                    buttonID="roi_consult"
                    setOpenPopupPolicy={props.setOpenPopupPolicy}
                    titleSubmit={a.param.titleSubmit}
                    onSubmitForm={props.onSubmitForm}
                    class={a.param.class}
                    button={a.param.button}
                    isFile={props.isFile}
                    title={
                      <>
                        <h2 className="form__title form__title_article">
                          {a.param.title}
                        </h2>
                        {a.param.subtitle !== "" ? (
                          <p className="form__subtitle form__subtitle_article">
                            {a.param.subtitle}
                          </p>
                        ) : null}
                      </>
                    }
                  />
                ) : null
              )}
            </div>
          </>
        ) : null
      )}
      <div className="faq__form-box">
        <div className="faq__form-box-title">
          <p className="faq__title">Остались вопросы?</p>
        </div>
        <div className="faq__form-box-subtitle">
          <div className="faq__title-box">
            <p className="faq__title-subtitle">Остались вопросы?</p>
            <p className="faq__subtitle">
              Задайте их{" "}
              <span className="faq__subtitle_span">инженеру теплотехнику</span>{" "}
              прямо сейчас
            </p>
          </div>
          <button
            className="faq__button faq__button_article"
            type="button"
            onClick={() => props.setOpenPopupQuestion(true)}
          >
            ЗАДАТЬ ВОПРОС инженеру теплотехнику
          </button>
        </div>
      </div>
    </section>
  );
}

export default ArticlesText;
