import "./Form.css";
import React from "react";

function Form(props) {
  const [isKey, setKey] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [spanQuestion, setSpanQuestion] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [spanName, setSpanName] = React.useState(false);
  const [spanTel, setSpanTel] = React.useState(false);
  const regex5 =
    /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[- ]?)?\(?\d{3,5}\)?[- ]?\d{1}[- ]?\d{1}[- ]?\d{1}[- ]?\d{1}[- ]?\d{1}(([- ]?\d{1})?[- ]?\d{1})?$/;
  const data = {
    title: props.titleSubmit,
    name: name,
    tel: phone,
    question: question,
    file: props.isFile,
  };

  function change(e) {
    // e.target.value.length <= 1 ? setPhone('8' + e.target.value) : setPhone(`${e.target.value.replace(/\s+/g, '').substring(0, 1)} ${e.target.value.replace(/\s+/g, '').substring(1, 4)} ${e.target.value.replace(/\s+/g, '').substring(4, 7)} ${e.target.value.replace(/\s+/g, '').substring(7, e.target.value.replace(/\s+/g, '').length)}`)
    // isKey === 8 && e.target.value.length <= 2 ? setPhone('+7') : isKey === 8 && e.target.value.length >= 2 ? setPhone(e.target.value) : e.target.value.length <= 2 || regex.test(e.target.value) || regex4.test(e.target.value) ? setPhone('+7') : regex.test(e.target.value) || regex4.test(e.target.value) ? setPhone('+7') : setPhone(`${e.target.value.replace(/\s+/g, '').substring(0, 2)} ${e.target.value.replace(/\s+/g, '').substring(2, 5)} ${e.target.value.replace(/\s+/g, '').substring(5, 8)} ${e.target.value.replace(/\s+/g, '').substring(8, 10)} ${e.target.value.replace(/\s+/g, '').substring(10, e.target.value.replace(/\s+/g, '').length)}`);
    isKey === 8 && e.target.value.length <= 2
      ? setPhone("+7")
      : isKey === 8 && e.target.value.length >= 2
      ? setPhone(e.target.value.replace(/\s+/g, ""))
      : e.target.value.length >= 2
      ? setPhone(
          `${e.target.value
            .replace(/\s+/g, "")
            .substring(0, 2)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(2, 5)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(5, 8)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(8, 10)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(10, e.target.value.replace(/\s+/g, "").length)}`
        )
      : setPhone("+7");
  }
  function onClear() {
    setPhone("");
    setName("");
    setQuestion("");
    setSpanTel(false);
    setSpanName(false);
    setSpanQuestion(false);
    setDisabled(true);
  }
  function onSubmit(e) {
    e.preventDefault();
    props.onSubmitForm(data);
    onClear();
  }
  return (
    <section id={props.id} className={props.class}>
      {props.title}
      <form className="form">
        <div className="input__label">
          <span
            className={
              spanName ? "input__span input__span_active" : "input__span"
            }
          >
            Имя
          </span>
          <input
            className={
              spanName
                ? "form__input form__input_min form__input_active form__input_active_min"
                : "form__input form__input_min"
            }
            name="name"
            type="text"
            placeholder="Как вас зовут?"
            autoComplete="off"
            minLength="2"
            maxLength="30"
            value={name}
            onFocus={() => setSpanName(true)}
            onBlur={(e) =>
              e.target.value === "" ? setSpanName(false) : setSpanName(true)
            }
            onChange={(e) => {
              setName(e.target.value);
              e.target.value.length !== "" &&
              phone.replace(/\s+/g, "").length > 11 &&
              regex5.test(phone)
                ? setDisabled(false)
                : setDisabled(true);
            }}
            required
          />
        </div>
        <div className="input__label">
          <span
            className={
              spanTel ? "input__span input__span_active" : "input__span"
            }
          >
            Телефон
          </span>
          <input
            className={
              spanTel
                ? "form__input form__input_min form__input_active form__input_active_min"
                : "form__input form__input_min"
            }
            name="tel"
            type="tel"
            placeholder="Ваш телефон"
            autoComplete="off"
            onFocus={(e) => {
              setSpanTel(true);
              e.target.value === "" ? setPhone("+7") : <></>;
            }}
            onBlur={(e) =>
              e.target.value === "" ? setSpanTel(false) : setSpanTel(true)
            }
            value={phone}
            onClick={(e) => {
              e.target.value.replace(/\s+/g, "").length !== 0 ? (
                <></>
              ) : (
                setPhone("+7")
              );
            }}
            minLength="16"
            maxLength="16"
            onKeyDown={(e) => {
              setKey(e.keyCode);
            }}
            onChange={(e) => {
              change(e);
              name !== "" &&
              e.target.value.replace(/\s+/g, "").length > 11 &&
              regex5.test(e.target.value)
                ? setDisabled(false)
                : setDisabled(true);
            }}
            required
          />
        </div>
        {props.children}
        {props.class === "form__container form__popup-question" ? (
          <div className="input__label">
            <span
              className={
                spanQuestion ? "input__span input__span_active" : "input__span"
              }
            >
              Ваш вопрос
            </span>
            <input
              className={
                spanQuestion
                  ? "form__input form__input_question form__input_active form__input_active_question"
                  : "form__input form__input_question"
              }
              name="question"
              type="text"
              placeholder="Задайте ваш вопрос"
              autoComplete="off"
              minLength="10"
              maxLength="200"
              value={question}
              onFocus={() => setSpanQuestion(true)}
              onBlur={(e) =>
                e.target.value === ""
                  ? setSpanQuestion(false)
                  : setSpanQuestion(true)
              }
              onChange={(e) => {
                setQuestion(e.target.value);
                e.target.value.length !== "" &&
                name !== "" &&
                phone.replace(/\s+/g, "").length > 11 &&
                regex5.test(phone)
                  ? setDisabled(false)
                  : setDisabled(true);
              }}
              required
            />
          </div>
        ) : null}
        <div className="form__box">
          <button
            id={props.buttonID}
            className={
              isDisabled
                ? "form__button form__button_min form__button_disabled"
                : "form__button form__button_min"
            }
            disabled={isDisabled}
            type="submit"
            value="Оставить заявку"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(e);
            }}
          >
            {props.button}
          </button>
          <p className="form__policy form__policy_min">
            Нажимая на кнопку, вы принимаете{" "}
            <span
              className="form__policy_span form__policy_span_min"
              onClick={() => props.setOpenPopupPolicy(true)}
            >
              условия обработки данных
            </span>
          </p>
        </div>
      </form>
    </section>
  );
}

export default Form;
