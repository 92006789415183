import "./Header.css";
import React from "react";
import yaraiting from "../../images/yaraiting.png";
import yaraitingwebp from "../../images/yaraiting.webp";
import yaraitingavif from "../../images/yaraiting.avif";
import { useLocation } from "react-router-dom";
function Header(props) {
  const [isOpenMenu, setOpenMenu] = React.useState(false);
  const location = useLocation();
  return (
    <div className="header">
      <div className="header__menu-box">
        <div className="header__box">
          <button
            type="button"
            className="header__button-menu"
            onClick={() => setOpenMenu(true)}
          ></button>
          <div className="header__logo-box">
            <a
              className="header__logo-link"
              href={location.pathname === "/otoplenie" ? "/otoplenie" : "/"}
            >
              <picture>
                <source srcSet={props.logowebpload} type="image/webp" />
                <source srcSet={props.logoavifload} type="image/avif" />
                <source srcSet={props.logoload} type="image/png" />
                <img
                  className="header__logo"
                  src={props.logoload}
                  alt="logo"
                  type="image/png"
                />
              </picture>
            </a>
            <p className="header__logo-text">
              Водяной теплый пол "под&nbsp;ключ" в Санкт-Петербурге и Лен.
              области
            </p>
          </div>
          <a
            className="header__raiting-link"
            target="_blank"
            rel="noreferrer"
            href="https://yandex.ru/maps/org/khomitek/69053393631/?ll=30.366048%2C59.885982&z=17"
          >
            <picture>
              <source srcSet={yaraitingwebp} type="image/webp" />
              <source srcSet={yaraitingavif} type="image/avif" />
              <source srcSet={yaraiting} type="image/png" />
              <img
                className="header__raiting"
                src={yaraiting}
                alt="logo"
                type="image/png"
              />
            </picture>
          </a>
          <p className="header__adress">
            ул. Бухарестская 24, кopп. 1 оф. 205, м. Бухарестская
          </p>
          <div className="header__tel-box">
            <p className="header__text">Пн-Пт, 10.00-19.00</p>
            <a className="header__tel comagic_phone" href="tel:+78122148090">
              8 812 214 80 90
            </a>
            <button
              className="header__tel-button"
              onClick={() => props.setOpenPopupForm(true)}
              type="button"
            >
              Заказать звонок
            </button>
          </div>
          <div className="header__mail-box">
            <div className="header__box-mess">
              <a
                className="header__social-link comagic_phone"
                href="tel:+78122148090"
              >
                {null}
              </a>
              <a
                className="header__social-link"
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/79119200901?text=Здравствуйте.+Нужна+консультация+по+водяному+теплому+полу."
              >
                {null}
              </a>
              <a
                className="header__social-link"
                target="_blank"
                rel="noreferrer"
                href="viber://chat?number=%2B79818392025"
              >
                {null}
              </a>
              <a
                className="header__social-link"
                target="_blank"
                rel="noreferrer"
                href="https://t.me/masenkov"
              >
                {null}
              </a>
            </div>
            <a className="header__mail" href="tel:info@homitec.ru">
              info@homitec.ru
            </a>
          </div>
        </div>
      </div>
      <nav
        className={
          isOpenMenu ? "header__menu header__menu_mob_active" : "header__menu "
        }
      >
        <a
          className={
            props.LinkActive === "calculate"
              ? "header__link header__link_active"
              : "header__link"
          }
          href={
            location.pathname === "/otoplenie" || location.pathname === "/"
              ? "#calculate"
              : "https://homitec.spb.ru/#calculate"
          }
          onClick={() => {
            props.setLinkActive("calculate");
            setOpenMenu(false);
          }}
        >
          Рассчитать стоимость
        </a>
        <a
          className={
            props.LinkActive === "case"
              ? "header__link header__link_active"
              : "header__link"
          }
          href={
            location.pathname === "/otoplenie" || location.pathname === "/"
              ? "#case"
              : "https://homitec.spb.ru/#case"
          }
          onClick={() => {
            props.setLinkActive("case");
            setOpenMenu(false);
          }}
        >
          Наши работы
        </a>
        {/* <a
        className={props.LinkActive === "comanda" ? "header__link header__link_active" : "header__link"} 
        href="#comanda"
        onClick={()=> {props.setLinkActive('comanda'); setOpenMenu(false)}}>
          Команда
        </a> */}
        <a
          className={
            props.LinkActive === "stages"
              ? "header__link header__link_active"
              : "header__link"
          }
          href={
            location.pathname === "/otoplenie" || location.pathname === "/"
              ? "#stages"
              : "https://homitec.spb.ru/#stages"
          }
          onClick={() => {
            props.setLinkActive("stages");
            setOpenMenu(false);
          }}
        >
          Этапы работ
        </a>
        <a
          className={
            props.LinkActive === "articles"
              ? "header__link header__link_active"
              : "header__link"
          }
          href={
            location.pathname === "/otoplenie" || location.pathname === "/"
              ? "#articles"
              : "https://homitec.spb.ru/#articles"
          }
          onClick={() => {
            props.setLinkActive("articles");
            setOpenMenu(false);
          }}
        >
          Статьи
        </a>
        <a
          className={
            props.LinkActive === "faq"
              ? "header__link header__link_active"
              : "header__link"
          }
          href={
            location.pathname === "/otoplenie" || location.pathname === "/"
              ? "#faq"
              : "https://homitec.spb.ru/#faq"
          }
          onClick={() => {
            props.setLinkActive("faq");
            setOpenMenu(false);
          }}
        >
          Вопросы
        </a>
        <a
          className={
            props.LinkActive === "contacts"
              ? "header__link header__link_active"
              : "header__link"
          }
          href={
            location.pathname === "/otoplenie" || location.pathname === "/"
              ? "#contacts"
              : "https://homitec.spb.ru/#contacts"
          }
          onClick={() => {
            props.setLinkActive("contacts");
            setOpenMenu(false);
          }}
        >
          Контакты
        </a>
        <button
          type="button"
          className="header__button-menu_close"
          onClick={() => setOpenMenu(false)}
        ></button>
      </nav>
    </div>
  );
}

export default Header;
