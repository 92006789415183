import React from "react";
import article1_avtor from "../images/director.png";
import article1_1 from "../images/article/1/1.png";
import article1_1webp from "../images/article/1/1.webp";
import article1_1avif from "../images/article/1/1.avif";
import article1_2 from "../images/article/1/2.png";
import article1_2webp from "../images/article/1/2.webp";
import article1_2avif from "../images/article/1/2.avif";
import article1_3 from "../images/article/1/3.png";
import article1_3webp from "../images/article/1/3.webp";
import article1_3avif from "../images/article/1/3.avif";
import article1_4 from "../images/article/1/4.png";
import article1_4webp from "../images/article/1/4.webp";
import article1_4avif from "../images/article/1/4.avif";
import article1_5 from "../images/article/1/5.png";
import article1_5webp from "../images/article/1/5.webp";
import article1_5avif from "../images/article/1/5.avif";
import article1_6 from "../images/article/1/6.png";
import article1_6webp from "../images/article/1/6.webp";
import article1_6avif from "../images/article/1/6.avif";
import article1_7 from "../images/article/1/7.png";
import article1_7webp from "../images/article/1/7.webp";
import article1_7avif from "../images/article/1/7.avif";
import article1_8 from "../images/article/1/8.png";
import article1_8webp from "../images/article/1/8.webp";
import article1_8avif from "../images/article/1/8.avif";
import article1_9 from "../images/article/1/9.png";
import article1_9webp from "../images/article/1/9.webp";
import article1_9avif from "../images/article/1/9.avif";
import article1_10 from "../images/article/1/10.png";
import article1_10webp from "../images/article/1/10.webp";
import article1_10avif from "../images/article/1/10.avif";
import article1_11 from "../images/article/1/11.png";
import article1_11webp from "../images/article/1/11.webp";
import article1_11avif from "../images/article/1/11.avif";
import article1_12 from "../images/article/1/12.png";
import article1_12webp from "../images/article/1/12.webp";
import article1_12avif from "../images/article/1/12.avif";
import article1_13 from "../images/article/1/13.png";
import article1_13webp from "../images/article/1/13.webp";
import article1_13avif from "../images/article/1/13.avif";
import article1_14 from "../images/article/1/14.png";
import article1_14webp from "../images/article/1/14.webp";
import article1_14avif from "../images/article/1/14.avif";
import article1_15 from "../images/article/1/15.png";
import article1_15webp from "../images/article/1/15.webp";
import article1_15avif from "../images/article/1/15.avif";
import article1_16 from "../images/article/1/16.png";
import article1_16webp from "../images/article/1/16.webp";
import article1_16avif from "../images/article/1/16.avif";
import article1_17 from "../images/article/1/17.png";
import article1_17webp from "../images/article/1/17.webp";
import article1_17avif from "../images/article/1/17.avif";

export const ArticleContext = React.createContext();
export const articles = [
  {
    key: 1,
    translit:
      "Klient_ostalsya_dovolen_rezultatom_nesmotrya_na_to_chto_nahodilsya_za_1000_km_ot_obekta",
    src: `${article1_1}`,
    webp: `${article1_1webp}`,
    avif: `${article1_1avif}`,
    source: "",
    author: "Сергей Масенков",
    foto: `${article1_avtor}`,
    job: "Руководитель компании Хомитек, инженер с 10-ти летним стажем",
    date: "31.01.2025",
    title:
      "Клиент остался доволен результатом, несмотря на то что находился за 1000 км от объекта.",
    subtitle:
      "Комфорт, безопасность и экономия «под ключ». Смонтировали самые современные инженерные коммуникации в частном доме: канализация, водоснабжение, водяной теплый пол, радиаторы",
    text: [
      {
        type: "main-subtitle",
        text: "Комфорт, безопасность и экономия «под ключ». Смонтировали самые современные инженерные коммуникации в частном доме: канализация, водоснабжение, водяной теплый пол, радиаторы",
      },
      {
        type: "text",
        text: "Заказчик обратился к нам по рекомендации знакомых с задачей монтажа самых современных инженерных коммуникаций в своём каркасном доме. Мы предложили клиенту полный комплекс услуг, включая канализацию, водоснабжение, водяной тёплый пол и радиаторы.",
      },
      {
        type: "text",
        text: "Клиент находился на расстоянии около 1000 километров от объекта, поэтому контролировал процессы монтажа удаленно. На каждом этапе мы отправляли фото и видео отчеты о проделанной работе, что позволяло заказчику всегда быть в курсе. В результате клиент остался доволен качеством выполненных работ и уровнем сервиса.",
      },
      {
        type: "reference",
        text: [
          '"Я не стал лезть туда, в чем не разбираюсь. Просто доверился профессионалам и получил отличный результат."',
          "Заказчик Сергей.",
        ],
      },
      {
        type: "text",
        text: "В кейсе рассказываем, какое установили самое современное оборудования, чтобы обеспечить безопасность, создать комфорт и экономить средства заказчика.",
      },
      {
        type: "form",
        param: {
          titleSubmit: "Статья. Записывайтесь на встречу в офис.",
          class: "form__container form__zamer form__zamer_article",
          button: "ОСТАВИТЬ ЗАЯВКУ",
          title:
            "Мы учтем особенности и смонтируем самые современные коммуникации для комфортной жизни в вашем доме. Записывайтесь на встречу в офис.",
          subtitle: "",
        },
      },
      {
        type: "subtitle",
        text: "Информация об объекте:",
      },
      {
        type: "list",
        text: [
          "Деревня Екатериновка",
          "Дом двухэтажный, каркасный, на бетонных сваях для семьи из 2-х человек",
          "Общая площадь: 90 кв.м.",
        ],
      },
      {
        type: "subtitle",
        text: "Запрос клиента:",
      },
      {
        type: "text",
        text: "Провести коммуникации, для комфорта всех членов семьи. Чтобы можно было регулировать температуру в помещении, подключить стиральную и посудомоечную машину, принимать душ, поливать огород, мыть автомобиль, контролировать состояние дома на расстоянии с помощью современных программ.",
      },
      {
        type: "subtitle",
        text: "Результат:",
      },
      {
        type: "list",
        text: [
          "Длительность работы: 3 недели (с 12 июля по 30 июля)",
          "Общая стоимость 990 тыс. р. из них: монтаж — 340 тыс. р., материалы — 650 тыс. р.",
          "Монтаж труб водоснабжения и канализации в частном доме на два санузла, кухню, стиральную и посудомоечные машины, душ.",
          "Отопление: водяной теплый пол на 1-м этаже и установка радиаторов на 2-м. С возможностью регулировать температуру в помещениях.",
          "Установка поливочного крана с теплой и холодной водой",
        ],
      },
      {
        type: "title",
        text: "Выявили потребности и составили смету",
      },
      {
        type: "text",
        text: "Вместе с клиентом встретились в нашем уютном офисе, где за чашечкой ароматного кофе открыли проект дома на большой 65 диагональной смарт-доске. В ходе беседы поняли, что клиент хотел бы установить в доме, показали какие есть возможности и варианты.",
      },
      {
        type: "img",
        src: `${article1_1}`,
        srcWebp: `${article1_1webp}`,
        srcAvif: `${article1_1avif}`,
        source: "",
        text: "Проект дома на смарт-доске в нашем офисе",
      },
      {
        type: "text",
        text: "После того как определились с системой отопления, выполнили теплорасчет. Он помог убедиться, что выбранной системы отопления хватает для комфортной температуры дома в любое время года. ",
      },
      {
        type: "text",
        text: "Дальше очередь за расчетом сметы в специально разработанном современном калькуляторе, который выводим на экран. Во время подсчета на экране видно, что входит в смету, как будет выглядеть оборудование и работать. Смету получаем точную на 100%.",
      },
      {
        type: "text",
        text: "После составления сметы, написали подробное техническое задание, в котором расписали каждую позицию монтажа.",
      },
      {
        type: "text",
        text: "После расчета точной сметы и распечатки техзадания, подписали договор с гарантией 6 лет. И приступили к установке.",
      },
      {
        type: "form",
        param: {
          titleSubmit:
            "Статья. Заявка на консультацию, выезд замерщика, расчет точной сметы",
          class: "form__container form__zamer form__zamer_article",
          button: "ОСТАВИТЬ ЗАЯВКУ",
          title:
            "Получите консультацию, выезд замерщика, расчет точной сметы БЕСПЛАТНО",
          subtitle: "",
        },
      },
      {
        type: "title",
        text: "Смонтировали канализацию",
      },
      {
        type: "text",
        text: "Дом заказчика каркасный, на бетонных сваях, поэтому канализацию сделали в свайном поле под фундаментом 1-го этажа. У клиента всегда будет беспрепятственный доступ к системе.",
      },
      {
        type: "text",
        text: "Также предусмотрели ревизии для канализации. Если из-за неправильной эксплуатации трубы засоряться, их прочистить не составит труда.",
      },
      {
        type: "text",
        text: "Учли уклоны и крепеж, чтобы конструкция работала долгие годы и не требовала к себе внимания.",
      },
      {
        type: "img",
        src: `${article1_2}`,
        srcWebp: `${article1_2webp}`,
        srcAvif: `${article1_2avif}`,
        source: "",
        text: "Канализацию в частном доме сделали в свайном поле под фундаментом 1-го этажа. Клиент получил свободный доступ к конструкции. В случае засора из-за неправильной эксплуатации можно будет быстро прочистить трубы",
      },
      {
        type: "title",
        text: "Водоснабжение — одинаковый напор воды во всем доме",
      },
      {
        type: "text",
        text: "Смонтировали трубы водоснабжения на 2 санузла: душ, раковина, унитаз, стиральная машина, кухонная мойка.",
      },
      {
        type: "text",
        text: "Разводку водоснабжения выполнили коллекторным способом. Такая система подает воду к каждой точке (душ, раковина на кухне) с одинаковым давлением. Поэтому тот, кто будет принимать душ, даже не заметит перепада из-за того, что включили воду на кухне или запустили посудомоечную машину.",
      },
      {
        type: "img",
        src: `${article1_3}`,
        srcWebp: `${article1_3webp}`,
        srcAvif: `${article1_3avif}`,
        source: "",
        text: "Монтаж системы водоснабжения в частном доме коллекторным способом",
      },
      {
        type: "text",
        text: "Установили надежный бойлер косвенного нагрева на 150 л., который быстро нагревается с помощью электрического котла мощностью 9 кВт. В доме постоянно будет горячая вода в нужном количестве. Не будет проблемы, если все члены семьи захотят принять душ друг за другом.",
      },
      {
        type: "img",
        src: `${article1_4}`,
        srcWebp: `${article1_4webp}`,
        srcAvif: `${article1_4avif}`,
        source: "",
        text: "Установка бойлера косвенного нагрева на 150 л. и монтаж котла отопления мощностью 9 кВт в частном доме",
      },
      {
        type: "text",
        text: "Чтобы вода не промерзала при отрицательной температуре, смонтировали греющий кабель прямо внутри трубы водопровода. Такое решение помогает поддерживать в трубах необходимую температуру и предотвращает образование ледяных пробок. Даже в самые сильные морозы дом не останется без воды.",
      },
      {
        type: "img",
        src: `${article1_5}`,
        srcWebp: `${article1_5webp}`,
        srcAvif: `${article1_5avif}`,
        source: "",
        text: "Монтаж греющего кабеля, который защитит воду от промерзания",
      },
      {
        type: "text",
        text: "Установили фильтры для очистки воды от примесей и песка. Это защитит систему от повреждений, если в воде окажутся посторонние частицы. А стиральная и посудомоечная машина прослужат намного дольше.",
      },
      {
        type: "img",
        src: `${article1_6}`,
        srcWebp: `${article1_6webp}`,
        srcAvif: `${article1_6avif}`,
        source: "",
        text: "Монтаж фильтров для защиты системы водоснабжения",
      },
      {
        type: "text",
        text: "Смонтировали поливочные антизамерзающие краны на улице. Их легко перекрыть на зиму, чтобы вода не замерзла. И заново включить весной, чтобы, например, помыть машину или полить огород.",
      },
      {
        type: "text",
        text: "Два крана сделали с холодной водой и один с горячей. Горячая вода пригодится, чтобы наполнить бассейн для детей.",
      },
      {
        type: "img",
        src: `${article1_7}`,
        srcWebp: `${article1_7webp}`,
        srcAvif: `${article1_7avif}`,
        source: "",
        text: "Смонтировали в частном доме поливочные антизамерзающие краны на улице",
      },
      {
        type: "text",
        text: "Небольшой лайфхак. Поливочный кран в систему водоснабжения мы подключили до магистрального фильтра, чтобы не расходовать ресурс фильтра в пустую. Ведь, чтобы помыть машину не нужна фильтрованная вода.",
      },
      {
        type: "title",
        text: "Исправили недочеты строителей, чтобы смонтировать водяной теплый пол",
      },
      {
        type: "text",
        text: "Изначально строители не предусмотрели, что на 1-м этаже запланирована установка водяного теплого пола. Они оставили только 10 см. высоты, вместо нужных 12-14 см.",
      },
      {
        type: "text",
        text: "Ограничения по высоте не давали смонтировать водяной теплый пол, как принято, на маты с бобышками. Мы придумали выход из ситуации. Вместо матов мы решили использовать сетку. Уложили 20 мм утеплителя с сеткой плюс стяжка 6,5 см. Это помогло не отказываться от установки водяного теплого пола в доме, несмотря на ограничения по высоте.",
      },
      {
        type: "text",
        text: "Установили термостаты в спальне, кухне и гостиной. С их помощью каждый член семьи сможет задать комфортную температуру в своей комнате за счет регулирования температуры теплых полов. Кто любит, когда в комнате свежо — установит температуру пониже, а кто любит даже зимой ходить в майке и шортах — сделает потеплее.",
      },
      {
        type: "img",
        src: `${article1_10}`,
        srcWebp: `${article1_10webp}`,
        srcAvif: `${article1_10avif}`,
        source: "",
        text: "Монтаж водяного теплого пола в частном доме под ключ",
      },
      {
        type: "form",
        param: {
          titleSubmit: "Статья. Заявка на 3 СМЕТЫ",
          class: "form__container form__smeta form__zamer_article",
          button: "ОСТАВИТЬ ЗАЯВКУ",
          title: "ПОЛУЧИТЬ 3 СМЕТЫ",
          subtitle: "3 сметы под разный бюджет: эконом, стандарт, премиум",
        },
      },
      {
        type: "title",
        text: "Установили радиаторы",
      },
      {
        type: "text",
        text: "На 2-м этаже подобрали по мощности и установили 6-ть стальных панельных радиаторов с нижним подключением.",
      },
      {
        type: "text",
        text: "Подводящие трубы к радиаторам 2-го этажа проложили по потолку 1-го этажа. Такое решение помогло спрятать разводку труб в пол, чтобы комната выглядела эстетично и красиво.",
      },
      {
        type: "img",
        src: `${article1_13}`,
        srcWebp: `${article1_13webp}`,
        srcAvif: `${article1_13avif}`,
        source: "",
        text: "Монтаж отопления радиаторов в частном доме под ключ",
      },
      {
        type: "text",
        text: "На каждый радиатор установили термоголовку. Достаточно просто повернуть ее на определенную цифру, чтобы уменьшить или увеличить температуру в помещении.",
      },
      {
        type: "img",
        src: `${article1_14}`,
        srcWebp: `${article1_14webp}`,
        srcAvif: `${article1_14avif}`,
        source: "",
        text: "Монтаж отопления радиаторов в частном доме под ключ",
      },
      {
        type: "title",
        text: "В управлении разберется даже ребенок",
      },
      {
        type: "text",
        text: "Промаркировали все трубы в разводке. Теперь легко определить, какой кран нужно открыть, чтобы включить или отключить холодную воду в раковине или унитазе.",
      },
      {
        type: "img",
        src: `${article1_15}`,
        srcWebp: `${article1_15webp}`,
        srcAvif: `${article1_15avif}`,
        source: "",
        text: "Все трубы в разводке промаркированы — легко понять, какой кран перекрыть, чтобы отключить воду",
      },
      {
        type: "title",
        text: "Экономия с современной GSM автоматикой",
      },
      {
        type: "text",
        text: "Мы смонтировали водяной теплый пол со стяжкой и подружили Автоматику Зонт с термостатами заказчика. Благодаря этому можно максимально эффективно использовать ночной тариф электроэнергии для экономии электричества.",
      },
      {
        type: "reference",
        text: [
          "Автоматика ZONT управляет инженерными системами в доме, как в режиме реального времени, так и по программируемому алгоритму.",
        ],
      },
      {
        type: "text",
        text: "Например, настроить расписание: ночью стяжка нагревается от электрокотла по сниженному тарифу, а днем подогрев автоматически снижается. Комфортную температуру в комнате обеспечивает тепло, которое идет от нагретой за ночь стяжки.",
      },
      {
        type: "img",
        src: `${article1_16}`,
        srcWebp: `${article1_16webp}`,
        srcAvif: `${article1_16avif}`,
        source: "",
        text: "Автоматика ZONT гарантируем безопасность дома",
      },
      {
        type: "title",
        text: "Подключили контроль отопления дома удаленно",
      },
      {
        type: "text",
        text: "Автоматика Зонт позволяет клиенту скачать приложение на телефон, которое позволит контролировать систему отопления и управлять температурой в доме из любой точки мира, где есть интернет. Приложение сразу сообщит об аварии, отключении электричества, что температура в доме или давление упало. Клиент сможет вовремя среагировать и устранить проблему даже на расстоянии. ",
      },
      {
        type: "img",
        src: `${article1_17}`,
        srcWebp: `${article1_17webp}`,
        srcAvif: `${article1_17avif}`,
        source: "",
        text: "Сообщение о потере связи с котлом отопления, которое приходит на телефон заказчику",
      },
    ],
  },
];
